<template>
    <section class="content-wrapper">
        <pageLoader v-show="ploader" :key="key"/>
        <div class="row">
            <div class="col-md-12">
                <div class="blog-details-inner">
                    <div class="blog-detail-title">
                        <h1 class="text-center">{{posts.title}}</h1>
                        <h4 class="text-center">publié le <span>{{posts.datepublished}}</span></h4>
                    </div>
                    <div class="blog-large-pic">
                            <div v-if='![posts.image]'>
                                pas d'image
                            </div>
                            <picture v-else slot="img">
                                <source :srcset="posts.image.content.webpImage"  type="image/webp">
                                <img class="img-fluid" :src="posts.image.content.originalImage" alt="image du blog"/>
                            </picture>
                            <!-- <img class="img-fluid" src="../assets/images/import/blog.jpg"> -->
                    </div>
                    
                    <!-- <div class="my-5">contenu posts : {{ posts }}</div> -->
                    <!-- contenu dynamique -->
                    <div v-for="(blocks, blockIsd) in blocks"  class="col blocks" :class="blocks.column" :key="blockIsd" >
                  
                        <div v-for="(block, blockId) in blocks.data" :key="blockId">
                            <div v-html="block.data.text">
                            </div>
                            <!-- <component
                                :is="block.element"
                                v-bind="_properties(block.element, block, section)"
                                v-dynamic-events:[{block:block,data:block.data,section:section}]="
                                    Allevent[block.element]
                                "
                                :key="blockId+''+ssb_key"
                                >
                            </component> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { dateMixin } from './mixins/dates';
import { d_properties } from "./preview/properties.js";
import event from "./preview/event-list.json";
export default {    
    name:'blog',
    mixins:[dateMixin],
    data() {
        return {
            posts:{},
            blocks:'',
            Allevent: event,
            ploader:true
        }
    },
    directives: {
    DynamicEvents: {
      bind: (el, binding, vnode) => {
        const allEvents = binding.value;
        Object.keys(allEvents).forEach((event) => {
          vnode.componentInstance.$on(event, (eventData) => {
            const targetEvent = allEvents[event];
            vnode.context[targetEvent](
              eventData,
              binding.arg.data,
              binding.arg.block,
              binding.arg.section
            );
          });
        });
      },
      unbind: function (el, binding, vnode) {
        vnode.componentInstance.$off();
      },
    },
  },
    methods:{
        _properties: d_properties,
        getPosts(){
            let ulid = this.$route.params.ulid
            axios.get(`posts/${ulid}`)
            .then(resPosts => {
                this.posts = resPosts.data
                this.blocks = resPosts.data.blocks
                this.ploader = false
            })
            .catch(errPosts => console.error(errPosts))
        }
    },
    mounted(){
        this.getPosts()
    }
}
</script>

<style scoped>
</style>